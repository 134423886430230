import React, { useRef } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from '@emotion/styled'

import 'swiper/css/swiper.css';
import Swiper from 'react-id-swiper';

const tabLinkClasses = 'gibson fw-500 fs-25 m-fs-20 m-fw-700 m-roboto'
const Hero = ({ active, fluid, onClickFn, description, title }) => {

    const aosA = {
        'data-aos': 'fade-in',
        'data-aos-delay': 500,
        'data-aos-duration': 1000
    }
    const aosB = {
        'data-aos': 'fade-in',
        'data-aos-delay': 1000,
        'data-aos-duration': 1000,
        'data-aos-anchor': '.about-us-title'
    }

    return (
        <StyledSlide className="hero is-fullheight">
            <BackgroundImage fluid={fluid} className="hero-body">
                <div className="gradient-background" style={{ background: 'none' }}>
                    <div className="container">
                        <div className="columns" style={{ justifyContent: 'center' }}>
                            <div className="column is-6">
                                <h1 className="fs-35 m-fs-37 fw-600 has-text-white gibson about-us-title" {...aosA}>
                                    Save Money,<br />
                                Save The Planet, Be Happy
                            </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </BackgroundImage>
            <StyledFoot>
                <StyledNavTabs className="" {...aosB}>
                    <div className="container">
                        <div className="columns">
                            <ul className="column is-11">
                                <li className={active == 0 ? "is-active" : ''}>
                                    <a onClick={() => { onClickFn(0) }}>
                                        <h3 className={tabLinkClasses}>What<span> We Do</span></h3>
                                        <p className="s-fs-12">do we do?</p>
                                    </a>
                                </li>
                                <li className={active == 1 ? "is-active" : ''}>
                                    <a onClick={() => { onClickFn(1) }}>
                                        <h3 className={tabLinkClasses}>Why<span> We Do It</span></h3>
                                        <p className="s-fs-12">do we do it?</p>
                                    </a>
                                </li>
                                <li className={active == 2 ? "is-active" : ''}>
                                    <a onClick={() => onClickFn(2)}>
                                        <h3 className={tabLinkClasses}>How<span> We Do It</span></h3>
                                        <p className="s-fs-12">do we do it?</p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </StyledNavTabs>
                <div style={{ background: '#fff' }}>
                    <div className="container">
                        <div className="columns" style={{ justifyContent: 'center', textAlign: 'center' }} {...aosB}>
                            <div className="column is-8">
                                <h3 className="fs-18 roboto fw-400 m-fs-20 m-fw-500 m-lh-15">
                                    {title}
                                </h3>
                                <p className="fs-15 fw-100 m-fs-14">{description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </StyledFoot>
        </StyledSlide>
    )
}


export default () => {

    const ref = useRef(null);


    const goTo = (i) => {
        if (ref.current !== null && ref.current.swiper !== null)
            ref.current.swiper.slideTo(i);

    };

    const params = {
        preloadImages: false,
        centeredSlides: true,
        effect: 'fade',
        allowTouchMove: false,
        speed: 1000
    };


    return (
        <StaticQuery
            query={graphql`
                query {
                    hero0: file(relativePath: { eq: "about/1-Forestroad.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 1500) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    hero1: file(relativePath: { eq: "about/2-Football.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 1500, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    hero2: file(relativePath: { eq: "about/3-Drone.jpg" }) {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 1500) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                }
            `}
            render={data => {
                return (
                    <div className={`slider`} id="products">
                        <Swiper {...params} ref={ref}>
                            <div className="in-active">
                                <Hero
                                    active={0}
                                    fluid={data.hero0.childImageSharp.fluid}
                                    onClickFn={(newIndex) => { goTo(newIndex) }}
                                    title={'Educate, Guide, Install and Maintain'}
                                    description={`Our goal is to educate and deliver. 
                                    Education leads to confident decision making, and once you make your decision to go solar with KOTA we will deliver a 
                                    hassle-free experience from start to finish by taking care of everything for you.`}
                                />
                            </div>
                            <div className="in-active">
                                <Hero
                                    active={1}
                                    fluid={data.hero1.childImageSharp.fluid}
                                    onClickFn={(newIndex) => { goTo(newIndex) }}
                                    title={'There’s a better way to power your life'}
                                    description={`With energy costs on the rise, an ageing electric grid, and endless environmental hazards brought on by consuming fossil fuels,
                                    there’s never been a more crucial time to switch to renewables. At KOTA we are committed to providing you with the
                                    best possible experience in making the switch.`}
                                />
                            </div>
                            <div className="in-active">
                                <Hero
                                    active={2}
                                    fluid={data.hero2.childImageSharp.fluid}
                                    onClickFn={(newIndex) => { goTo(newIndex) }}
                                    title={'We care'}
                                    description={`From your initial contact with KOTA, all the way through installation and system activation, we are hyper focused on making sure you know we care. 
                                    You’ll see it in the details of our every day operations. 
                                    We care about you, we care about your experience going solar, and we care about our planet. `}
                                /></div>
                        </Swiper >
                    </div >
                )
            }}
        />
    )
}

const StyledSlide = styled('div')`
    height: calc(100vh - 75px);
    background: #000;
    .hero-body{
        padding: 0 !important;
        flex: 1;
    }
    .is-6{
        @media screen and (max-width:1199px){
            width: 70%;
            margin-left: auto;
            margin-right: auto
        }
    }
    .gradient-background{
        display: flex;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.1) 100%);
        text-align: center;
        h1{
            margin-top: 150px;
            @media screen and (max-width:1199px){
                margin-top: 14.973958333333333rem;
            }
            @media screen and (max-width:959px){
                width: 80%;
                margin-left: 10%;
            }

            @media screen and (max-width:420px){
                width: 95%;
                margin-left: 2.5%;
                margin-top: 30rem;
            }
        }
    }
`


const StyledFoot = styled('div')`
    & > div{
        padding-top: 40px;
        padding-bottom: 40px;
        @media screen and (max-width:1199px){
            padding-top: 5.729166666666667rem;
            padding-bottom: 6.777rem;
            min-height: 170px;
        }
        h3{
            margin-bottom: 16px;
            @media screen and (max-width:1199px){
                width: 80%;
                margin-left: auto;
                margin-right: auto;
            }
        }
        

        @media screen and (max-width:420px){
            padding: 53px 30px;
            min-height: 180px;
            h3{
                width: 100%;
                margin-left: 0;
                text-align: left;
            }
            p{
                text-align: left;
            }
        }

        .column.is-7{
            @media screen and (max-width:1199px){
                width: calc(100% - 16.6666666rem);
                margin: auto;
            }
        }
    }
`

const StyledNavTabs = styled('nav')`
    margin-top: -99px;
    &:not(:last-child){
        margin-bottom: 0;
    }

    
    
    ul{
        display: flex;
        margin: 0 auto;
        
    }
    li{
        width: 33.33%;
        text-align: center;
        a{  
            display: inline-block;
            width: 100%;
            padding-top: 30px;
            padding-bottom: 30px;
            flex-direction: column;
            border-radius: 4px 4px 0 0;
            & > *{
                color: #fff;
            }
            @media screen and (min-width:1200px){

                p{
                    display: none;
                }
            }
        } 
        &.is-active{
            
            a{
                background: #fff;
                & > *{
                    color: #000;
                }
            }
        }
    }

    @media screen and (max-width:1199px){
        margin-top: -84px;
        ul{
            &.column.is-11{
                width: 50%;
            }
            li{
                h3{
                    line-height: 1.3em;
                    text-transform: uppercase;
                }
                a{
                    padding-top: 9px;
                    padding-bottom: 9px;
                }
                span{
                    display: none;
                }
            }
            
        }
    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation: portrait){
        margin-top: -69px;
    }

    @media screen and (max-width:768px){
        margin-top: -67px;
    }
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation: landscape){
        margin-top: -87px;
    }
    @media screen and (max-width:420px){
        margin-top: -13.526568911956522rem;
        ul{
            padding: 0;
            &.column.is-11{
                width: 90%;
            }
            li{
                a{
                    padding-top: 2.173913043478261rem;
                    padding-bottom: 2.173913043478261rem;
                }
            }
        }
    }
    
    
`