import React, { useState } from 'react'
import Img from 'gatsby-image';
import styled from '@emotion/styled'

export default ({ name, role, image, intro = '', extended = '' }) => {
    const [isOpen, setOpen] = useState(false)
    return (
        <StyledMember className="columns is-mobile">
            <div className="column is-one-fifth">
                <div className="image">
                    <Img fluid={image} />
                </div>
            </div>
            <div className="column is-four-fifths">
                <h3 className="roboto fs-24 s-fs-22">{name}
                    <span className="divider">|</span>
                    <span className="fw-100 role">{role}</span></h3>
                <p className="fs-16 m-fs-14  fw-100">{intro}</p>
                <div className={!isOpen ? 'is-hidden' : ''}>
                    {extended.map((e, i) => (
                        <p className="fs-16 m-fs-14 fw-100" key={'para_' + i}>{e}</p>
                    ))}
                </div>
                <button className={'our-button is-uppercase roboto fs-12 fw-400' + (isOpen ? ' is-dark' : '')} onClick={
                    () => setOpen(!isOpen)
                }>
                    {isOpen ? 'Read Less' : 'Read More'}
                </button>
            </div>
            <div className="column is-small is-full">
                <p className="fs-16 m-fs-14  fw-100">{intro}</p>
                <div className={!isOpen ? 'is-hidden' : ''}>
                    {extended.map((e, i) => (
                        <p className="fs-16 m-fs-14 fw-100" key={'para_' + i}>{e}</p>
                    ))}
                </div>
                <button className={'our-button is-uppercase roboto fs-12 fw-400' + (isOpen ? ' is-dark' : '')} onClick={
                    () => setOpen(!isOpen)
                }>
                    {isOpen ? 'Read Less' : 'Read More'}
                </button>
            </div>

        </StyledMember >

    )
}

const StyledMember = styled.div`
    flex-wrap: wrap;
    &:not(:last-child){
        margin-bottom: 83px;
        @media screen and (max-width: 1199px){
            margin-bottom: 10.807291666666667rem;
        }
    }
    @media screen and (max-width: 768px){
        padding-left: 70px;
        padding-right: 70px;
    }
    @media screen and (max-width: 420px){
        padding-left: 30px;
        padding-right: 30px;
    }
    h3{
        @media screen and (min-width: 421px){
            margin-bottom: 2.734375rem;
        }
    }
    p{
        margin-bottom: 1em;
        color: #707070;
    }
    .is-one-fifth{
        padding-right: 0;
    }
    .image{
        border-radius: 100%;
        .gatsby-image-wrapper{
            border-radius: 100%;
        }
    }
    .role{
        color: #D6182B;
    }
    .divider{
        color: #707070;
        padding: 0 28px;
        @media screen and (max-width: 420px){
            padding: 0 14px;
        }
    }
    .is-four-fifths{
        @media screen and (min-width: 1200px){
            padding-left: 60px;
        }
        @media screen and (max-width: 1199px){
            padding-left: 3.90625rem;
        }
        @media screen and (min-width: 421px){
            flex: none;
        }
        @media screen and (max-width: 420px){
            display: flex;
            align-items: center;
            padding-left: 14px;
        }
    }
    .our-button{
        color: #ACACAC;
        background: none;
        border: 1px solid #707070;
        border-radius: 15px;
        padding: 5px 19px;
        height: auto;
        cursor: pointer;
        &:focus{
            outline: none;
        }
        &.is-dark{
            background: #000000;
            border: 1px solid #000;
        }
    }

    /* Flip Flop strucutre for mobile */
    .is-four-fifths{
        @media screen and (max-width: 420px){
            p, div, button{
                display: none;
            }
        }
    }
    .is-small{
        @media screen and (min-width: 421px){
            display: none;
        }
    }
    
`