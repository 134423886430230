import React, { useEffect } from 'react';
import Layout from '../components/layout';
import Hero from '../components/about/hero';
import Team from '../components/about/team';
import "aos/dist/aos.css";
export default () => {
    let AOS;

    useEffect(() => {
        // Server-side rendering does not provide the 'document' object
        // therefore this import is required either in useEffect as exclusively executed on a client
        const AOS = require("aos");
        AOS.init({
            once: true,
        });
    }, []);

    useEffect(() => {
        if (AOS) {
            AOS.refresh();
        }
    });
    return (
        <Layout>
            <Hero />
            <Team />
        </Layout>
    )
}

