import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import Member from './member'


export default () => (
    <StaticQuery
        query={graphql`
            query {
                cole: file(relativePath: { eq: "about/team/cole.jpg" }) {
                    childImageSharp {
                        fluid(quality: 70, maxWidth: 400, maxHeight: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                scott: file(relativePath: { eq: "about/team/scott.jpg" }) {
                    childImageSharp {
                        fluid(quality: 70, maxWidth: 400, maxHeight: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                cozzie: file(relativePath: { eq: "about/team/cozzie.jpg" }) {
                    childImageSharp {
                        fluid(quality: 70, maxWidth: 400, maxHeight: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                kevin: file(relativePath: { eq: "about/team/kevin.jpg" }) {
                    childImageSharp {
                        fluid(quality: 70, maxWidth: 400, maxHeight: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                tydus: file(relativePath: { eq: "about/team/tydus.jpg" }) {
                    childImageSharp {
                        fluid(quality: 70, maxWidth: 400, maxHeight: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                tony: file(relativePath: { eq: "about/team/tony.jpg" }) {
                    childImageSharp {
                        fluid(quality: 70, maxWidth: 400, maxHeight: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                
            }
        `}
        render={data => {
            const members = [
                {
                    name: 'Cole De Arman',
                    role: 'CEO',
                    image: data.cole.childImageSharp.fluid,
                    intro: `
                    Cole is a passionate leader that places people and the team above everything else. 
                    He is uncompromising when it comes to pushing for perfection and never being satisfied in all things KOTA does. 
                    He has been one of the instrumental visionaries of KOTA’s direction and strategy. 
                    Today, Cole is focused on growing and building the inside sales and operations teams.
                    `,
                    extended: [`
                    His start in solar began in 2008 with Verengo Solar as an Inside Sales Manager where he built and led a department of 
                    Inside Sales Reps and Managers selling solar over the phone. He left Verengo as the Director of Inside sales to pursue 
                    a career with a solar finance company, based out of San Diego, CA. That then led him to further challenge himself and 
                    become the Director of Sales Operations for American Solar Direct. That is where he would eventually meet the now 
                    President of KOTA, Kevin Radebaugh. Cole has constantly challenged himself to learn different facets of the solar industry 
                    and that experience has aided in making KOTA what it is today.`,
                        `
                    He still resides in beautiful San Diego, CA. with his wife and son. He enjoys adventures with his family, surfing, and golfing.
                    `]
                },
                {
                    name: 'Scott Hyde',
                    role: 'CRO',
                    image: data.scott.childImageSharp.fluid,
                    intro: `
                    Scott is a passionate entrepreneur who has lived by optimism and an unshakeable belief in abundance and hard work. 
                    Growing up pulling nails from scrap lumber and cleaning up job sites at his father’s custom home projects, he learned that some things 
                    can’t be done unless you roll up your sleeves and get to work. He grew up on a small ranch, raising sheep, cows, pigs, and horses and 
                    earned money through the 4-H program to buy his school clothes and saved enough to serve a mission for his church.
                    `,
                    extended: [`
                    Before his entrance to the door to door sales world, Scott worked for construction companies focused on providing superior customer 
                    experience through relationship driven sales. Having worked as an operator both in local and overseas markets, he learned the importance 
                    of being detail driven, granular focused, and finding true “value add” for his customers. He was one of the original 10 members of the 
                    startup formed by Protection One, Bright Energy where he led all sales reps company wide from inception to his departure. 
                    He opened offices all across southern California until Bright Energy was sold  off as part of the M&A of ADT and Protection One. 
                    He led Vision Solar/ Ion Solar for several years as the Director of Sales, California.
                    `,
                        `
                    As the CRO for KOTA Energy Group, Scott has become the driving force for both the vision and the strategy of the company and relies 
                    on the unique blend of talents of his executive team to build a company that embodies their core values of Become. Create. Give.
                    `
                    ]
                },
                {
                    name: 'Michael Cozzie',
                    role: 'CFO',
                    image: data.cozzie.childImageSharp.fluid,
                    intro: `
                    Michael grew up in the south side of Chicago from a humble beginnings and learned the value of entrepreneurship with a paper route at age 7, 
                    where he achieved his goal and bought his first drum set. Before moving to the west coast, Cozzie owned and operated a Conoco gas station and learned 
                    how to turn a profit on margins squeezed from convenience store candy bars and purchasing gas on futures. Learning about renewable energy in 2014, 
                    Mike bet his future on it, selling his store and moving to California the next year where he opened a new office for Vivint Solar in San Luis Obispo. 
                    In his first year, with very little resources given to him, Mike quickly became the number one sales rep in the company, personally responsible for 
                    nearly 50 installs a quarter. Mike grew through Vivint’s leadership channel until they shut down his market and he transitioned to Ion Solar where 
                    he met and began working with Scott Hyde. Their partnership formed half of what would be KOTA Energy Group.
                    `,
                    extended: [`Mike’s ability to track, analyze, and export data to usable and functional information allows KOTA to maximize profits where so 
                    many others in the industry have failed. His stewardship over every job provides a level of accountability through automation and algorithms 
                    that is second only to his love the band, RUSH.`,
                    ]
                },
                {
                    name: 'Kevin Radebaugh',
                    role: 'President',
                    image: data.kevin.childImageSharp.fluid,
                    intro: `
                    Kevin grew up in the hotbed of door to door sales, right in the heart of Utah county. As a self proclaimed “hippy”, Kevin first saw the world 
                    differently living in Brazil after high school. He found that humanitarian efforts and being a steward of the earth were very meaningful and inspiring to him. 
                    Serving a mission for his church in Michigan, Radebaugh learned selflessness and hard work and his love for his fellowman deepened. Despite his desire to show 
                    off his creative flair on roller blades, he instead channeled that energy into his sales roles at Vivint. Working closely with the current leaders of Vivint Inc 
                    and Vivint Solar, Kevin applied his natural affinity to “gameify” and improve the experience for himself and his teams. In 2013 Kevin aligned his love for nature 
                    and the “going green” movement to his career and began selling solar door to door. Later at American Solar Direct, as the Director of Sales, he met and 
                    worked closely with Cole De Arman and formed what would become the other half of KOTA Energy Group.
                    `,
                    extended: [`
                    As the President of KOTA, Kevin is able to focus on one of our core values almost exclusively. Create a better experience. 
                    Making ridiculous amounts of money was never most important to Kevin. Instead, he loved more altruistic purposes and anything that improved the experience of his efforts. 
                    Naturally, Kevin has made the sales experience and sales culture focused on making sure that whomever is touched by KOTA becomes a better person because of it.
                    `,
                    ]
                },
                {
                    name: 'Tydus Queiroz',
                    role: 'CTO',
                    image: data.tydus.childImageSharp.fluid,
                    intro: `
                    Tydus oversees the company's tech strategy -- including the design, engineering, and development of technological products for employees and consumers. 
                    He is currently designing the ground-breaking software that simplifies the solar process allowing companies to minimize time and capital spent in customer acquisition. 
                    In addition, he’s taken on the responsibility of overseeing daily development of essential frameworks to customer management as the company ramps up for its next 
                    phase in vertical integration.
                    `,
                    extended: [
                        `
                        Tydus helped pioneer and launch multiple software developments with in the solar space. 
                        While working for Vivint Solar, the technologies created increased regional sales across 13 states and were a major preparation piece in the securing $300 Million in new financing. 
                        He created the first round of prototypes for Solar City’s sales platform prior to the company being purchased by Tesla.
                        `,
                        `Tydus is fascinated at taking complex problems and creating simplified solutions. After walking away after a year at Brigham Young University to take the entrepreneur’s path, 
                        he began a career in marketing and sales helping his first company grow to 13 million a year in sales volume. 
                        A self taught designer and developer, his passion stems from an early childhood of computer and video gaming. 
                        By studying the simple interfaces and the way games make complex procedures amusing, Tydus has found a formula for design and development, 
                        leaving the users in a state of natural understanding and the “ultimate triad” of an experience that is fun, fast, and easy.`,
                        `An avid practicing meditator with focuses in pranayama and holotropic respiration, Tydus believes these practices to be the primary source of inspiration and creation.`
                    ]
                },
                {
                    name: 'Tony Hyde',
                    role: 'VP Direct Sales',
                    image: data.tony.childImageSharp.fluid,
                    intro: `
                    As an Idaho native and country boy, Tony has always recognized the value of hard work and commitment. He strives everyday to exceed expectations. 
                    Prior to entering sales he served a 2 year service LDS mission to Micronesia, Guam. 
                    His first summer of door knocking sales began in 2004 while additionally taking business classes at LDS Business College in Salt Lake City. 
                    Following, he went full force into summer sales building top offices, recruiting hundreds of sales reps and hitting top levels for multiple well known companies.
                    `,
                    extended: [
                        `
                        He has had manager positions, as well as Director of Sales at Eclipse Marketing, I-Satellite, Pinnacle Security, Capital Connect, and ION Solar. 
                        These companies and leadership roles helped instill the discipline it takes to build the most powerful, and trust worthy solar teams he has now. 
                        He has a keen ability to work with and relate to colleagues on every level.
                        `,
                        `He currently works for KOTA Energy Group as Vice President of Sales. Outside of sales, Tony is married and has 5 children. 
                        He enjoys hiking, basketball, and skiing. He currently resides in Provo Utah.`
                    ]
                }
            ]




            return (
                <div style={{ background: '#fff' }}>
                    <div style={{
                        backgroundColor: '#F9F9F9',
                        paddingTop: '100px',
                        background: 'linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.0) 50%)'
                    }}>
                        <h2 className="gibson fs-35 s-fs-28 fw-600 " style={{ textAlign: 'center' }} id="leadership-title">Our leadership team</h2>
                        <div className="columns" style={{ justifyContent: 'center' }}>
                            <TeamContainer className="column is-two-thirds">
                                {
                                    members.map(({ name, role, image, intro, extended }, i) => (
                                        <Member
                                            name={name}
                                            role={role}
                                            image={image}
                                            intro={intro}
                                            extended={extended}
                                            key={'member_' + i}

                                        />
                                    ))
                                }
                            </TeamContainer>
                        </div>
                    </div>
                </div>
            )
        }}
    />
)

const TeamContainer = styled('div')`
    &.column.is-two-thirds{
        margin-bottom: 100px;
        margin-top: 100px;
        @media screen and (max-width: 1199px){
            width: calc(100% - 18.229166666666667rem);
            margin: 10rem auto 10.807291666666667rem;
        }
        @media screen and (max-width: 420px){
            padding-bottom: 50px;
        }

        
    }
`